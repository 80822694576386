<template>
  <Container :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <div class="full_image mb_6rem">
        <div v-html="content"></div>
      </div>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Container from "../components/Container";
import NoData from "@/components/NoData";
export default {
  components: {
    Container,
    NoData,
  },
  data() {
    return {};
  },
  watch: {
    helpData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        } else {
          this.changeSpinnerStatus(false);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("help", ["helpData"]),
    payment() {
      return this.$route.query.method.toLowerCase() + "channel";
    },
    content() {
      return this.helpData[0].content;
    },
    checkResponseStatus() {
      return this.helpData && this.helpData.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("help", ["getHelp"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
  },
  beforeRouteEnter(to, from, next) {
    let payments = {
      alipay: {
        title: "支付宝",
      },
      WeChat: {
        title: "微信",
      },
      BankCard: {
        title: "银行卡",
      },
    };
    to.meta.name = payments[to.query.method].title ? payments[to.query.method].title + "教程" : "银行卡" + "教程";
    next();
  },
  mounted() {
    if (this.payment) {
      this.getHelp({
        code: this.payment,
        page: 1,
        limit: 20,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.full_image {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
